import React, { useState } from 'react'
import emailjs from '@emailjs/browser'
import District from "./Districts.json"

function PhoneContactUSGeneralForm() {
    const [formDetails, setFormDetails] = useState({
        Name: "",
        phoneNumber: "",
        Email: "",
        State: "",
        District: "",
        Message: "",
        Pincode: ""
    })
    const [emptyForm, setEmptyForm] = useState(false)
    const [districtsSelect, setDistrictsSelect] = useState([])
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleFormData = (event) => {
        const { name, value } = event.target
        setFormDetails({
            ...formDetails,
            [name]: value
        })
        if (name === "State") {
            const districtSelect = District.states.find(stateObject => stateObject.state === value)
            setDistrictsSelect(districtSelect ? districtSelect.districts : [])
        }
        if (emptyForm) {
            setEmptyForm(false)
        }
    }

    const sendEmail = () => {
        setIsLoading(true); // Start loading when email is being sent

        const templateParams = {
            from_name: formDetails.Name,
            from_phone: formDetails.phoneNumber,
            from_email: formDetails.Email,
            your_state: formDetails.State,
            your_district: formDetails.District,
            your_pincode: formDetails.Pincode,
            to_email: 'srstyres97@gmail.com',
            message: formDetails.Message,
        };

        emailjs.send('service_pp7v87q', 'template_77we437', templateParams, 'BHNILRXTRGgN2So2x')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setFormDetails({
                    Name: "",
                    phoneNumber: "",
                    Email: "",
                    State: "",
                    District: "",
                    Message: "",
                    Pincode: ""
                })
                setIsLoading(false); // Stop loading
                alert('Message sent successfully!');
            }, (err) => {
                console.log('FAILED...', err);
                setIsLoading(false); // Stop loading
                alert('Failed to send the message. Please try again.');
            });
    };

    const handleSendButton = () => {
        if (Object.values(formDetails).some(value => value === "")) {
            setEmptyForm(true)
        } else {
            sendEmail();
        }
    }

    const stateSelect = District.states.map((item, i) => <option value={item.state} key={i}>{item.state}</option>)
    const districtSelectDropDown = districtsSelect.map((item, i) => <option value={item} key={i}>{item}</option>)

    return (
        <div className="Phone_contact_us_general_inquiry_form">
            {/* Loading Overlay */}
            {isLoading && (
                <div className="loading-overlay">
                    <img src="./icons/Settings_loader.gif" alt="Loading..." />
                </div>
            )}

            <div className="Phone_contact_us_general_inquiry_input">
                <label>Name</label>
                {emptyForm && formDetails.Name === "" && <p className='error_form'>Please provide your Full Name</p>}
                <input name='Name' value={formDetails.Name} className={emptyForm && formDetails.Name === "" ? "error_input" : ""} onChange={handleFormData} type="text" />
            </div>
            <div className="Phone_contact_us_general_inquiry_input">
                <label>Email address</label>
                {emptyForm && formDetails.Email === "" && <p className='error_form'>Please provide your Email</p>}
                <input name='Email' value={formDetails.Email} className={emptyForm && formDetails.Email === "" ? "error_input" : ""} onChange={handleFormData} type="email" />
            </div>
            <div className="Phone_contact_us_general_inquiry_input">
                <label>Phone Number</label>
                {emptyForm && formDetails.phoneNumber === "" && <p className='error_form'>Please provide your Phone Number</p>}
                <input name='phoneNumber' value={formDetails.phoneNumber} className={emptyForm && formDetails.phoneNumber === "" ? "error_input" : ""} onChange={handleFormData} type="number" />
            </div>
            <div className="Phone_contact_us_general_inquiry_input">
                <label>State</label>
                {emptyForm && formDetails.State === "" && <p className='error_form'>Please provide your State</p>}
                <select name='State' className={emptyForm && formDetails.State === "" ? "error_input" : ""} value={formDetails.State} onChange={handleFormData}>
                    <option value="" disabled>Select state</option>
                    {stateSelect}
                </select>
            </div>
            <div className="Phone_contact_us_general_inquiry_input">
                <label>District</label>
                {emptyForm && formDetails.District === "" && <p className='error_form'>Please provide your District</p>}
                <select name='District' className={emptyForm && formDetails.District === "" ? "error_input" : ""} value={formDetails.District} onChange={handleFormData}>
                    <option value="" disabled>Select District</option>
                    {districtSelectDropDown}
                </select>
            </div>
            <div className="Phone_contact_us_general_inquiry_input">
                <label>Pin Code</label>
                {emptyForm && formDetails.Pincode === "" && <p className='error_form'>Please provide your Pincode</p>}
                <input name='Pincode' value={formDetails.Pincode} className={emptyForm && formDetails.Pincode === "" ? "error_input" : ""} onChange={handleFormData} type="number" />
            </div>
            <div className="Phone_contact_us_general_inquiry_input">
                <label>Write your message here</label>
                {emptyForm && formDetails.Message === "" && <p className='error_form'>Please provide your Message</p>}
                <textarea name='Message' value={formDetails.Message} className={emptyForm && formDetails.Message === "" ? "error_input" : ""} onChange={handleFormData}></textarea>
            </div>
            <button onClick={handleSendButton}>Send</button>
        </div>
    )
}

export default PhoneContactUSGeneralForm;
