import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import District from './Districts.json';

function ContactUsgeneralFormPage() {
    const [Formdetails, setFormDetails] = useState({
        Name: '',
        phoneNumber: '',
        Email: '',
        State: '',
        District: '',
        Message: '',
        Pincode: '',
    });

    const [EmptyForm, setEmptyForm] = useState(false);
    const [Districtsselect, setDistrictsselect] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // New loading state

    const onHandleFormData = (event) => {
        let eventName = event.target.name;
        let eventValue = event.target.value;
        setFormDetails({
            ...Formdetails,
            [eventName]: eventValue,
        });
        if (eventName === 'State') {
            const districtSelect = District.states.filter(
                (stateObject) => stateObject.state === eventValue && eventValue !== ''
            );
            setDistrictsselect(districtSelect[0].districts);
        }
        if (EmptyForm === true) {
            setEmptyForm(false);
        }
    };

    const sendEmail = () => {
        setIsLoading(true); // Start loading

        const templateParams = {
            from_name: Formdetails.Name,
            from_phone: Formdetails.phoneNumber,
            from_email: Formdetails.Email,
            your_state: Formdetails.State,
            your_district: Formdetails.District,
            your_pincode: Formdetails.Pincode,
            to_email: 'srstyres97@gmail.com',
            message: Formdetails.Message,
        };

        emailjs.send('service_pp7v87q', 'template_77we437', templateParams, 'BHNILRXTRGgN2So2x')
            .then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    setFormDetails({
                        Name: '',
                        phoneNumber: '',
                        Email: '',
                        State: '',
                        District: '',
                        Message: '',
                        Pincode: '',
                    });
                    setIsLoading(false); // Stop loading
                    alert('Message sent successfully!');
                },
                (err) => {
                    console.log('FAILED...', err);
                    setFormDetails({
                        Name: '',
                        phoneNumber: '',
                        Email: '',
                        State: '',
                        District: '',
                        Message: '',
                        Pincode: '',
                    });
                    setIsLoading(false); // Stop loading
                    alert('Failed to send the message. Please try again.');
                }
            );
    };

    const stateSelect = District.states.map((item, i) => (
        <option value={item.state} key={i}>
            {item.state}
        </option>
    ));
    const districtSelectDropDown = Districtsselect.map((item, i) => (
        <option value={item} key={i}>
            {item}
        </option>
    ));

    const handleSendButton = () => {
        if (
            Formdetails.District === '' ||
            Formdetails.Email === '' ||
            Formdetails.phoneNumber === '' ||
            Formdetails.State === '' ||
            Formdetails.Message === '' ||
            Formdetails.Name === '' ||
            Formdetails.Pincode === ''
        ) {
            setEmptyForm(true);
        } else {
            sendEmail();
        }
    };

    return (
        <div>
            {/* Loader Screen */}
            {isLoading && (
                <div className="loading-overlay">
                    <img src="./icons/Settings_loader.gif" alt="Loading..." />
                </div>
            )}

            <div className="input_row">
                <div className="input_element">
                    <label>Full Name</label>
                    {EmptyForm === true && Formdetails.Name === '' ? (
                        <p className="error_form">Please provide your Full Name</p>
                    ) : null}
                    <input
                        name="Name"
                        value={Formdetails.Name}
                        className={EmptyForm === true && Formdetails.Name === '' ? 'error_input' : ''}
                        onChange={onHandleFormData}
                        type="text"
                    />
                </div>
                <div className="input_element_1">
                    <label>Phone Number</label>
                    {EmptyForm === true && Formdetails.phoneNumber === '' ? (
                        <p className="error_form">Please provide your Phone Number</p>
                    ) : null}
                    <input
                        name="phoneNumber"
                        value={Formdetails.phoneNumber}
                        className={EmptyForm === true && Formdetails.phoneNumber === '' ? 'error_input' : ''}
                        onChange={onHandleFormData}
                        type="number"
                    />
                </div>
            </div>
            <div className="input_row" style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <div className="input_element_email">
                        <label>Email</label>
                        {EmptyForm === true && Formdetails.Email === '' ? (
                            <p className="error_form">Please provide your Email</p>
                        ) : null}
                        <input
                            name="Email"
                            value={Formdetails.Email}
                            className={EmptyForm === true && Formdetails.Email === '' ? 'error_input' : ''}
                            onChange={onHandleFormData}
                            type="email"
                        />
                    </div>
                    <div className="input_element_state">
                        <label>State</label>
                        {EmptyForm && Formdetails.State === '' ? (
                            <p className="error_form">Please provide your State</p>
                        ) : null}
                        <select
                            name="State"
                            className={EmptyForm === true && Formdetails.State === '' ? 'error_input' : ''}
                            value={Formdetails.State}
                            id="rim"
                            onChange={onHandleFormData}
                        >
                            <option value="" disabled selected>
                                Select state
                            </option>
                            {stateSelect}
                        </select>
                    </div>
                </div>
                <div style={{ display: 'flex', marginLeft: '6.2vw' }}>
                    <div className="distrcit_input_element_1">
                        <label>District</label>
                        {EmptyForm && Formdetails.District === '' ? (
                            <p className="error_form">Please provide your District</p>
                        ) : null}
                        <select
                            name="District"
                            className={EmptyForm === true && Formdetails.District === '' ? 'error_input' : ''}
                            id="rim"
                            value={Formdetails.District}
                            onChange={onHandleFormData}
                        >
                            <option value="" disabled selected>
                                Select District
                            </option>
                            {districtSelectDropDown}
                        </select>
                    </div>
                    <div className="pincode_element_1">
                        <label>Pincode</label>
                        {EmptyForm === true && Formdetails.Pincode === '' ? (
                            <p className="error_form">Please provide your Pincode</p>
                        ) : null}
                        <input
                            name="Pincode"
                            value={Formdetails.Pincode}
                            className={EmptyForm === true && Formdetails.Pincode === '' ? 'error_input' : ''}
                            onChange={onHandleFormData}
                            type="number"
                        />
                    </div>
                </div>
            </div>
            <div className="input_row">
                <div className="input_element_3">
                    <label>Write up your query here</label>
                    {EmptyForm === true && Formdetails.Message === '' ? (
                        <p className="error_form">Please provide your Message</p>
                    ) : null}
                    <textarea
                        name="Message"
                        value={Formdetails.Message}
                        className={EmptyForm === true && Formdetails.Message === '' ? 'error_input' : ''}
                        onChange={onHandleFormData}
                        type="text"
                    ></textarea>
                </div>
            </div>
            <div className="input_row">
                <button onClick={handleSendButton}>Send</button>
            </div>
        </div>
    );
}

export default ContactUsgeneralFormPage;
