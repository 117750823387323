import React, { useState } from 'react';
import Database from "./DataBase.json";
import Modal from 'react-modal';
import PhoneFooter from './PhoneFooter';
import NavBarMobile from './NavBarMobile';
import PhoneProductsPopup from './PhoneProductsPopup';

const PhoneProducts = () => {
  const Rim_Size = [16, 18, 20, 24, 25, 28, 30];
  const Width_Size = [6.00, 6.50, 7.50, 8.00, 8.30, 8.50, 9.00, 9.50, 11.20, 12.40, 13.60, 14.90, 14.00, 16.90, 18.00, 18.40];
  const Rim_Size_Select = Rim_Size.map((item, i) => <option key={i} value={item}>{item}</option>);
  const Width_Size_Select = Width_Size.map((item, i) => <option key={i} value={item}>{item}</option>);
  const[loadMoreStatus,setLoadMoreStatus]=useState()
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false
  );
  const [tyreFilters, setTyreFilters] = useState({
    Vehicle_Type: '',
    search: "",
    Category: "",
    Tyre_width: "",
    Rim_Size: "",
    load_more: 6
  });

  const bindProductList = () => {
    let selectProducts = [];

    // Search filter
    selectProducts = Database.Products.filter((item) => {
      let searchfilter = item.Code + " " + item.Size + " " + item.Description + " " + item.Vehicle_Type + " " + item.Used_In;
      return searchfilter.toLowerCase().includes(tyreFilters.search.toLowerCase());
    });

    // Vehicle Type filter
    if (tyreFilters.Vehicle_Type) {
      selectProducts = selectProducts.filter(item => item.Vehicle_Type.toLowerCase().includes(tyreFilters.Vehicle_Type.toLowerCase()));
    }

    // Category filter
    if (tyreFilters.Category) {
      selectProducts = selectProducts.filter(item => item.Category.includes(tyreFilters.Category));
    }

    // Tyre Width filter
    if (tyreFilters.Tyre_width) {
      selectProducts = selectProducts.filter(item => String(item.Tyre_width) === tyreFilters.Tyre_width);
    }

    // Rim Size filter
    if (tyreFilters.Rim_Size) {
      selectProducts = selectProducts.filter(item => String(item.Rim_Size) === tyreFilters.Rim_Size);
    }

    // Map selected products to JSX elements
    let JSXProducts = selectProducts.map((item, i) => (
      <div key={i} onClick={() => { setSelectedProduct(item.Code); setModalIsOpen(true); }} className="prod-mobile-cards" style={i % 3 === 0 ? { marginTop: '3.451vw' } : {}}>
        <img src="../icons/ourProd-tyre-rect.svg" alt="img" className="img-top" />
        <h5>{item.Size}</h5>
        <img src="../icons/ourProd-tyre-img.svg" alt="img" className="img-down" />
      </div>
    ));
    if(loadMoreStatus!==selectProducts.length)
      {
        setLoadMoreStatus(selectProducts.length)
      }
    return JSXProducts.slice(0, tyreFilters.load_more);
  };

  const handleVehicleType = (event) => {
    const eventValue = event.target.innerText;
    if (eventValue === "All tyres") {
      setTyreFilters({ ...tyreFilters, Vehicle_Type: "" });
    } else if (eventValue === "Agricultural Tyre") {
      setTyreFilters({ ...tyreFilters, Vehicle_Type: "Agricultural" });
    } else if (eventValue === "Industrial Tyre") {
      setTyreFilters({ ...tyreFilters, Vehicle_Type: "Industrial" });
    } else if (eventValue === "OTR") {
      setTyreFilters({ ...tyreFilters, Vehicle_Type: "OTR" });
    }
  };

  const handleLoadMoreButton = () => {
    setTyreFilters({ ...tyreFilters, load_more: tyreFilters.load_more + 3 });
  };

  return (
    <div>
      {/* Banner Section */}
      <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
        style={{content:{
          width:'80.425vw',
          overflow:'hidden',
          marginLeft:'-6vw',
          height:'155vw',
          paddingBottom:'1vw'
        }}}
      >
        <PhoneProductsPopup productID={selectedProduct} modalOpen={setModalIsOpen}/>
      </Modal>
      <NavBarMobile />
      <div className="prod-mobile-banner">
        <img src="../icons/Phone_product_banner.png" alt="banner" style={{ width: '100%', height: '98px' }} />
        <h2>Have your vehicle Tyre’d!</h2>
      </div>

      {/* Dropdown Section */}
      <div className="prod-mobile-heading-dropdown">
        <p>Our Products</p>
        <div className="prod-mobile-dropdown">
          <div>
            <label htmlFor="rim">Rim size in inches</label>
            <select name="rim" id="rim" onChange={(event) => setTyreFilters({ ...tyreFilters, Rim_Size: '' + event.target.value })} defaultValue="">
              <option value="" disabled>Select Rim Size</option>
              {Rim_Size_Select}
            </select>
          </div>
          <div>
            <label htmlFor="wi">Tyre width in inches</label>
            <select name="wi" id="wi" onChange={(event) => setTyreFilters({ ...tyreFilters, Tyre_width: '' + event.target.value })} defaultValue="">
              <option value="" disabled>Select width</option>
              {Width_Size_Select}
            </select>
          </div>
        </div>
        <i className="fa-solid fa-x"></i>
        <p className="clear_filter" onClick={() => setTyreFilters({ ...tyreFilters, Rim_Size: "", Tyre_width: "", Vehicle_Type: "", search: "", Category: "" })}>Clear Filter</p>
      </div>

      {/* Tyre Types Section */}
      <div className="prod-mobile-tyre-types">
        <ul className="prod-mobile-tyre">
          <li><button type="button" className={tyreFilters.Vehicle_Type === "" ? 'prod_selected_vehcile_type' : 'prod_vehcile_type'} onClick={handleVehicleType}>All tyres</button></li>
          <li><button type="button" className={tyreFilters.Vehicle_Type === "Agricultural" ? 'prod_selected_vehcile_type' : 'prod_vehcile_type'} onClick={handleVehicleType}>Agricultural Tyre</button></li>
          <li><button type="button" className={tyreFilters.Vehicle_Type === "Industrial" ? 'prod_selected_vehcile_type' : 'prod_vehcile_type'} onClick={handleVehicleType}>Industrial Tyre</button></li>
          <li><button type="button" className={tyreFilters.Vehicle_Type === "OTR" ? 'prod_selected_vehcile_type' : 'prod_vehcile_type'} onClick={handleVehicleType}>OTR</button></li>
        </ul>
      </div>

      {/* Content Section */}
      <div className="prod-mobile-content">
      {bindProductList().length!=0?bindProductList():<img src='https://static.vecteezy.com/system/resources/previews/010/856/652/large_2x/no-result-data-document-or-file-not-found-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-etc-vector.jpg' style={{width:'80vw',marginLeft:'00vw'}}/>}
      </div>

      {/* Load More Button */}
      {tyreFilters.load_more>loadMoreStatus?null:
      <div className="prod-lo-btn-mobile">
        {bindProductList().length<6?null:<button onClick={handleLoadMoreButton} type="button">Load more</button>}
      </div>
}

      {/* Footer Section */}
      <PhoneFooter />
    </div>
  );
};

export default PhoneProducts;
