import React from 'react'
import { useState ,useEffect} from 'react'
import Navbar from './navbar'
import ContactUsgeneralFormPage from './ContactUsgeneralFormPage'
import ContactUSRetreadingFromPage from './ContactUSRetreadingFromPage'
import PhoneContactUSGeneralForm from './PhoneContactUSGeneralForm'
import PhoneContactUsRetreadingForm from './PhoneContactUsRetreadingForm'
import Footer from './Footer'
import NavBarMobile from './NavBarMobile';
import PhoneFooter from './PhoneFooter'

function ContactUSPage() {

    const [Contactusgeneralformstate,Setcontactusgeneralform]=useState(true)

    useEffect(() => {
      // Scroll to the top of the page
      window.scrollTo(0, 0);
  }, []);
    const handleContactPage=()=>
    {
        if (Contactusgeneralformstate==true) {
            Setcontactusgeneralform(false)
        }
        else{
            Setcontactusgeneralform(true)
        }
    }
        const handleClickcall = () => {
          window.location.href = 'tel:9443712616'; // Replace with your phone number
        };

        const handleClickEmail = () => {
            window.location.href = 'mailto:srstyres97@gmail.com'; // Replace with your email address
          };
          const handleClickWhatsApp = () => {
            window.location.href = 'https://wa.me/9443712616?text=Hello%20I%20need%20assistance%20with...'; 
          };
  return (
    <div>
        <div className='laptop_contact_us'>
        <Navbar/>
        <div>
           <img src="./icons/contact_us_banner_png.png" style={{ width: '100%', marginTop: '-1px' }} alt="Banner" />
        </div>
        <div style={{ marginBottom: '6.53vw' }}>
            <p className="contact_us">Contact Us</p>
            <p className="contact_us_content">
                At <span className="contact_us_content_name"> S.R.S Tyres</span>, we're dedicated to providing outstanding customer service. You can easily reach us via email, WhatsApp, calls, or live enquiry at our office address. We pride ourselves on our quick response times and personalized support, ensuring every customer feels valued and heard. Your satisfaction is our priority, and we're here to assist you with any needs or questions. Contact us today and experience the quality service you deserve.
            </p>
        </div>
        <div className="contact_us_main">
            <div className="contact_us_method">
                <p className="contact_us_title">WhatsApp</p>
                <p className="contact_us_description">
                    For instant messaging, our WhatsApp support is available. Whether you need quick answers or detailed explanations, we're just a message away.
                </p>
                <button className="contact_us_button mail_button" onClick={handleClickWhatsApp}> <img onClick={handleClickWhatsApp} src="./icons/Phone_contact_us_whatsapp.svg" alt="WhatsApp" /></button>
            </div>

            <div className="contact_us_method" style={{ marginLeft: '3.268vw' }}>
                <p className="contact_us_title">Phone Call</p>
                <p className="contact_us_description">
                    Prefer a more personal touch? Give us a call! Our customer service representatives are ready to assist you with any inquiries or issues, providing you with the support you need in real-time.
                </p>
                <button className="contact_us_button phone_call_button" style={{marginLeft:'2.1vw',position:'relative',top:'-1vw'}}  onClick={handleClickcall}> <img onClick={handleClickcall} src="./icons/Phone_contact_us_call.svg" alt="Call" /></button>
            </div>

            <div className="contact_us_method" style={{ marginLeft: '3.268vw' }}>
                <p className="contact_us_title">Mail</p>
                <p className="contact_us_description">
                    Reach out to us anytime via email, and our friendly customer support team will respond promptly to address your concerns, answer your questions, and provide detailed information.
                </p>
                <button className="contact_us_button" onClick={handleClickEmail} style={{marginLeft:'2.1vw',position:'relative',top:'-1vw'}}>  <img onClick={handleClickEmail} src="./icons/Phone_contact_us_email.svg" alt="Email" /></button>
            </div>
        </div>
        <div style={{ height: '33.3vw', width: '100%', marginTop: '3.23vw' }}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1998598.9200869196!2d77.0405960083008!3d11.938906239993557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a535697ff27bfcf%3A0x55ce8dd47a704f02!2sSRS%20Tyre%20Retreading%20Company!5e0!3m2!1sen!2sin!4v1722765871534!5m2!1sen!2sin"
                style={{ border: 0, width: '100%', height: '33.3vw' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="SRS Tyre Retreading Company Location"
            ></iframe>
        </div>
        <div className="we_made_it_easy">
            <p style={{ color: '#1B0C75', fontSize: '51px' }}>We made it easy for you!</p>
            <p style={{ color: '#36454F', fontSize: '25.5px' }}>Fill out our contact form, and we'll get back to you as quickly as possible.</p>
        </div>
        <div className={Contactusgeneralformstate?"contact_form_general_enquiry":"contact_form_retreading_enquiry"
            }>
            <div className="heading_inquiry">
                <p onClick={handleContactPage} className="General_inqury">General Enquiry</p>
                <p onClick={handleContactPage}  className="retreading_inqury">Retreading Enquiry</p>
            </div>
            {Contactusgeneralformstate?<ContactUsgeneralFormPage/>:<ContactUSRetreadingFromPage/>}
        </div>
        <Footer/>
        </div>
        <div className='Phone_contact_us'>
          <NavBarMobile/>
      <div className="Phone_contact_us_banner">
        <img src="./icons/Phone_contact_us_banner.png" alt="Our Services Banner" />
      </div>
      <p className="Phone_contact_us_content">
        At <span>S.R.S Tyres</span>, we're dedicated to providing outstanding customer service. You can easily reach us via email, WhatsApp, calls, or live enquiry at our office address. We pride ourselves on our quick response times and personalized support, ensuring every customer feels valued and heard. Your satisfaction is our priority, and we're here to assist you with any needs or questions. Contact us today and experience the quality service you deserve.
      </p>
      <img className="Phone_conatct_us_line" src="./icons/Phone_contact_us.line1.svg" alt="Contact Us Line" />
      <p className="Phone_contact_us_heading">Contact Us</p>
      <div className="Phone_contact_us_call_email">
        <img onClick={handleClickWhatsApp} src="./icons/Phone_contact_us_whatsapp.svg" alt="WhatsApp" />
        <img onClick={handleClickcall} src="./icons/Phone_contact_us_call.svg" alt="Call" />
        <img onClick={handleClickEmail} src="./icons/Phone_contact_us_email.svg" alt="Email" />
      </div>
      <div className="Phone_contact_maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1998598.9200869196!2d77.0405960083008!3d11.938906239993557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a535697ff27bfcf%3A0x55ce8dd47a704f02!2sSRS%20Tyre%20Retreading%20Company!5e0!3m2!1sen!2sin!4v1722765871534!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="SRS Tyre Retreading Company Location"
        ></iframe>
      </div>
      <div className="Phone_contact_us_we_made">
        <p className="Phone_contact_us_we_made_heding">We made it easy for you!</p>
        <p className="Phone_contact_us_we_made_content">Fill out our contact form, and we'll get back to you as quickly as possible.</p>
      </div>
      <div className={Contactusgeneralformstate?"Phone_contact_us_general_inquiry":"Phone_contact_us_retreading_inquiry"}>
        <div className="Phone_contact_us_general_inquiry_choice">
          <p onClick={handleContactPage}>General Enquiry</p>
          <p onClick={handleContactPage} style={{ marginLeft: '18.667vw' }}>Retrading Enquiry</p>
        </div>
        {Contactusgeneralformstate?<PhoneContactUSGeneralForm/>:<PhoneContactUsRetreadingForm/>}
      </div>
      <PhoneFooter/>
    </div>
  
    </div>
  )
}

export default ContactUSPage