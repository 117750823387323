import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import District from "./Districts.json";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "./firebase"; 

function PhoneContactUsRetreadingForm() {
  const [formDetails, setFormDetails] = useState({
    Name: "",
    phoneNumber: "",
    Email: "",
    State: "",
    District: "",
    TyreSize: "",
    VehicleNameModel: "",
    Message: "",
    Pincode: ""
  });

  const [emptyForm, setEmptyForm] = useState(false);
  const [Districtsselect, setDistrictsselect] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [loaderstatus, setLoaderstatus] = useState(0); // Loader state

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track upload progress
        setLoaderstatus(1); // Show loading overlay
      },
      (error) => {
        console.error("Upload failed:", error);
      },
      () => {
        // Get the download URL after upload is complete
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImageUrl(url);
          console.log("File available at", url);
          setLoaderstatus(0); // Hide loading overlay
        });
      }
    );
  };

  const onHandleFormData = (event) => {
    const { name, value } = event.target;
    setFormDetails({
      ...formDetails,
      [name]: value
    });
    if (name === "State") {
      const districtSelect = District.states.filter(
        (stateObject) => stateObject.state === value && value !== ""
      );
      setDistrictsselect(districtSelect[0]?.districts || []);
    }
    if (emptyForm === true) {
      setEmptyForm(false);
    }
  };

  const sendEmail = () => {
    setLoaderstatus(1); // Show loading overlay when sending the email

    const templateParams = {
      from_name: formDetails.Name,
      from_phone: formDetails.phoneNumber,
      from_email: formDetails.Email,
      your_state: formDetails.State,
      your_district: formDetails.District,
      tyre_size: formDetails.TyreSize,
      vehicle_name_model: formDetails.VehicleNameModel,
      your_pincode: formDetails.Pincode,
      message: formDetails.Message,
      to_email: 'srstyres97@gmail.com',
      image_url: imageUrl
    };

    emailjs.send('service_pp7v87q', 'template_jlzogb8', templateParams, 'BHNILRXTRGgN2So2x')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setFormDetails({
          Name: "",
          phoneNumber: "",
          Email: "",
          State: "",
          District: "",
          TyreSize: "",
          VehicleNameModel: "",
          Message: "",
          Pincode: ""
        });
        setLoaderstatus(0); // Hide loading overlay
        alert('Message sent successfully!');
      }, (err) => {
        console.log('FAILED...', err);
        setLoaderstatus(0); // Hide loading overlay
        alert('Failed to send the message. Please try again.');
      });
  };

  const handleSendButton = () => {
    const { Name, phoneNumber, Email, State, District, Message, Pincode } = formDetails;
    if (Name === "" || phoneNumber === "" || Email === "" || State === "" || District === "" || Message === "" || Pincode === "") {
      setEmptyForm(true);
    } else {
      sendEmail();
    }
  };

  const stateSelect = District.states.map((item, i) => <option value={item.state} key={i}>{item.state}</option>);
  const districtSelectDropDown = Districtsselect.map((item, i) => <option value={item} key={i}>{item}</option>);

  return (
    <div>
      {/* Loading Overlay */}
       {/* Loading Overlay */}
       {loaderstatus === 1  && (
                <div className="loading-overlay">
                    <img src="./icons/Settings_loader.gif" alt="Loading..." />
                </div>
            )}


      <div className="Phone_contact_us_general_inquiry_form">
        <div className="Phone_contact_us_general_inquiry_input">
          <label>Name</label>
          {emptyForm && formDetails.Name === "" && <p className='error_form'>Please provide your Full Name</p>}
          <input name='Name' value={formDetails.Name} onChange={onHandleFormData} className={emptyForm && formDetails.Name === "" ? "error_input" : ""} />
        </div>
        <div className="Phone_contact_us_general_inquiry_input">
          <label>Email address</label>
          {emptyForm && formDetails.Email === "" && <p className='error_form'>Please provide your Email</p>}
          <input name='Email' value={formDetails.Email} onChange={onHandleFormData} className={emptyForm && formDetails.Email === "" ? "error_input" : ""} type="email" />
        </div>
        <div className="Phone_contact_us_general_inquiry_input">
          <label>Phone Number</label>
          {emptyForm && formDetails.phoneNumber === "" && <p className='error_form'>Please provide your Phone Number</p>}
          <input name='phoneNumber' value={formDetails.phoneNumber} onChange={onHandleFormData} className={emptyForm && formDetails.phoneNumber === "" ? "error_input" : ""} type="number" />
        </div>
        <div className="Phone_contact_us_general_inquiry_input">
          <label>State</label>
          {emptyForm && formDetails.State === "" && <p className='error_form'>Please provide your State</p>}
          <select name='State' value={formDetails.State} onChange={onHandleFormData} className={emptyForm && formDetails.State === "" ? "error_input" : ""}>
            <option value="" disabled>Select state</option>
            {stateSelect}
          </select>
        </div>
        <div className="Phone_contact_us_general_inquiry_input">
          <label>District</label>
          {emptyForm && formDetails.District === "" && <p className='error_form'>Please provide your District</p>}
          <select name='District' value={formDetails.District} onChange={onHandleFormData} className={emptyForm && formDetails.District === "" ? "error_input" : ""}>
            <option value="" disabled>Select District</option>
            {districtSelectDropDown}
          </select>
        </div>
        <div className="Phone_contact_us_general_inquiry_input">
          <label>Pin Code</label>
          {emptyForm && formDetails.Pincode === "" && <p className='error_form'>Please provide your Pincode</p>}
          <input name='Pincode' value={formDetails.Pincode} onChange={onHandleFormData} className={emptyForm && formDetails.Pincode === "" ? "error_input" : ""} type="number" />
        </div>
        <div className="Phone_contact_us_general_inquiry_input">
          <label>Vehicle Name & Model</label>
          <input name='VehicleNameModel' value={formDetails.VehicleNameModel} onChange={onHandleFormData} />
        </div>
        <div className="Phone_contact_us_general_inquiry_input">
          <label>Tyre Size</label>
          <input name='TyreSize' value={formDetails.TyreSize} onChange={onHandleFormData} />
        </div>
        <div className="Phone_contact_us_general_inquiry_input">
          <label>Details on service you need</label>
          {emptyForm && formDetails.Message === "" && <p className='error_form'>Please provide your Message</p>}
          <textarea name='Message' value={formDetails.Message} onChange={onHandleFormData} className={emptyForm && formDetails.Message === "" ? "error_input" : ""}></textarea>
        </div>
        <div className="Phone_contact_us_general_inquiry_input_file">
          <input onChange={handleImageUpload} type="file" />
          <img src="./icons/Phone_contact_us_retreading_file.svg" alt="File Upload Icon" />
        </div>
        <button onClick={handleSendButton}>Send</button>
      </div>
    </div>
  );
}

export default PhoneContactUsRetreadingForm;
